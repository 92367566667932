import React from 'react';
import { Row, Col, Navbar, Button, Container, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.png';

export default function Navigation() {
  return (
    <Navbar bg="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} className={'Logo'} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/events" style={{color: 'gold'}}>
              <b>Events</b>
            </Nav.Link>
            {/* <<<<<<< HEAD made some changes here, need to re-ad gold/bold link ref
            <Nav.Link color="gold" href="#">
              <b>Events</b> 
              {/* made some edits here - asim  }
            </Nav.Link>
            <Nav.Link href="#">???</Nav.Link>
            <Nav.Link href="/company"><b>our work</b></Nav.Link>
=======
            <Nav.Link href="/events">
              <b>Events</b>
            </Nav.Link>
            <Nav.Link href="/questions">???</Nav.Link>
            <Nav.Link href="/ourwork">our work</Nav.Link>
>>>>>>> e277c45136ac8700ee902ec3b2e8a7868bb9d454 */}
            <Nav.Link href="/questions">???</Nav.Link>
            <Nav.Link href="/ourwork">our work</Nav.Link>
            <Button href="/signup" variant="primary">Sign up</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
