import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Navigation from './Navigation';

export default function Company() {
  return (
    <React.Fragment>
      <div className={'Company'}>
        <Navigation />
        <div className="ContentWrapper">
          <Container>
            <Row>
              <h1>Welcome to the Asimula.. Stay tuned for more!</h1>
              <h3>Follow us on social media @asimulaofficial</h3>
              {/* <Col sm={12} md={{ span: 6, offset: 0 }}>
                <div className="Company__intro">
                  <h1>Asimula is...</h1>
                  <h4>
                    South Korea based VR entertainment platform company. We enables fans to easily meet their biases in real-time VR with
                    amazing interactivity … This is random text to fill the blank. Will be filled out later
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <div className="CEO"></div>
              </Col>
              <Col sm={12} md={6}>
                <div className="Company__mission">
                  <h1>Mission</h1>
                  <h4>We change the world by</h4>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
