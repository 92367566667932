import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Navigation from './Navigation';

export default function QuestionMarks() {
  return (
    <React.Fragment>
      <div className={'QuestionMarks'}>
        <Navigation />
        <div className="ContentWrapper">
          <h1>The community is not yet able to access our *march mystery access* page</h1>
        </div>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
