import './style.css';
import YoutubeBackground from 'react-youtube-background';
import { Canvas } from '@react-three/fiber';
import { Stars, OrbitControls } from '@react-three/drei';
import React, { useRef, Suspense, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import Scene from './Scene';
import Footer from './Footer';
import Navigation from './Navigation';
import Company from './Company';
import Events from './Events';
import QuestionMarks from './QuestionMarks';
import Signup from './Signup';
import { isMobile } from "react-device-detect";

export default function Home(props) {
  const myRef = useRef();
  const [signupNumber, setSignupNumber] = useState(6000); //this user count should be updated automatically (manual input for now)

  const listener = (e) => {
    // Rotating the beam scanner.
  
    try {
      myRef.current.rotation.y = 1 + window.pageYOffset * 0.007;
      
    } catch (err) {
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', listener, {passive: false}); // Page scrolling listener for 3D object rotation
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });


  function getContent() {
    
    // Swap the content according to the URL
    if (props.content === 'company') {
      return <Company />;
    } else if (props.content === 'events') {
      return <Events />;
    } else if (props.content === 'questions') {
      return <QuestionMarks />;
    } else if (props.content === 'signup') {
      return <Signup />;
    } else {
      return (
        
        <div className="Front">
          <Navigation />
          <div className="ContentWrapper  --snap">
            {' '}
            <Container fluid className={'--headLine'}>
              <div className="StarBackground">
                <Canvas>
                  <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade />
                </Canvas>
              </div>
              <Container>
                <Row className={'HeadLine'}>
                  <Col lg={6} sm={12} className={'HeadLine__column'}>
                    <h1>
                      Join <b>{signupNumber.toLocaleString('en', { useGrouping: true })}+</b> others in our world premiere event!
                    </h1>
                    <h5>Sign-up now and join our waitlist for early access!</h5>
                    <Button className={'--ticketButton'} href="/signup" variant="primary" size="lg">
                      Sign-Up Now
                    </Button>{' '}
                  </Col>

                  <Col lg={6} sm={16} className={'ThreeBackgroundWrapper'} >
                    {isMobile? 
                    <div className="ThreeBackground" style={{width:'100%', height: window.screen.width*0.9, pointerEvents: 'none'}}>
                      <div className="LiveButton">LIVE</div>
                      <Canvas className={'Stage'} >
                        <Suspense fallback={null} >
                            <Scene ref={myRef} />
                          <OrbitControls enableZoom={true} target={[0, 1, 0]} />
                        </Suspense>
                      </Canvas>
                    </div>
                    :
                    <div className="ThreeBackground" style={{width:'100%', height: window.screen.width*0.6, pointerEvents: 'none'}}>
                      <div className="LiveButton">LIVE</div>
                      <Canvas className={'Stage'} >
                        <Suspense fallback={null} >
                            <Scene ref={myRef} />
                          <OrbitControls enableZoom={true} target={[0, 1, 0]} />
                        </Suspense>
                      </Canvas>
                    </div>
                    }
                  </Col>
                </Row>
              </Container>
            </Container>
            <Container fluid className={'--demoVideo --snap'} >

            <YoutubeBackground
                videoId={'vEP7HVRZZYI'} /* default -> null */
                overlay={'rgba(0,0,0,0.4)'} /* default -> null | e.g. "rgba(0,0,0,.4)" */
              >
              </YoutubeBackground>

              <div style={{aspectRatio: '16/9', width : "100%", height: window.screen.width*0.5625}}>
                <iframe id="teaser_video"
                  style={{ aspectRatio: '16 / 9', width: '100%', height : "100%"}}
                  aspectRatio = '16/9'
                  src="https://www.youtube.com/embed/vEP7HVRZZYI?autoplay=1&mute=1&loop=1;playlist=vEP7HVRZZYI;"
                  width='100%'
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>

            </Container>

            <Container fluid className={'--getTicket --snap'}>
              <Container>
              <Row>
                  <h2 style={{paddingLeft: '20px', paddingRight: '20px'}}> Join the Pre-Launch Party!</h2>
                  {/* <h4>Available on</h4> */}
              </Row>
                <Row>
                  <Col className={'--extLink --brand'} sm={4} xs={12}>
                    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="1000px" height="810.206px" viewBox="0 0 1000 810.206">
                      <g>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M360.398,0.271c86.225-0.548,172.469-0.065,258.709-0.233
		c29.01-0.279,58.328,1.78,86.323,9.82c60.194,16.58,112.158,60.39,138.639,116.959c26.471,55.189,27.979,121.712,3.955,178.03
		c-22.927,55.306-69.84,99.997-126.096,120.413c-31.662,11.931-65.874,15.468-99.517,15.267c-83.6-0.032-167.217,0.07-250.816-0.049
		c-36.236-0.113-73.101-5.414-106.293-20.535c-46.52-20.38-85.444-57.663-107.655-103.347
		c-24.539-49.159-29.058-107.774-12.912-160.24c12.861-41.699,38.281-79.611,72.602-106.625
		c27.713-22.529,61.223-37.464,96.146-44.441C329.015,2.812,344.632,0.455,360.398,0.271L360.398,0.271z M313.583,161.107
		c-13.84,9.304-23.975,23.692-28.61,39.657c-4.983,18.657-2.808,39.407,7.395,55.97c12.145,20.934,35.17,34.009,59.009,36.168
		l299.118-0.149c16.943-2.344,33.909-8.971,45.97-21.446c10.861-10.436,18.224-24.49,20.415-39.41
		c4.335-23.706-4.785-49.208-22.879-65.058c-15.63-14.423-37.445-20.068-58.312-20.036c-89.697,0-179.396-0.032-269.091,0.017
		C348.12,146.603,328.915,150.292,313.583,161.107L313.583,161.107z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M582.172,550.362c13.162-0.119,26.335-0.064,39.513-0.016
		c-0.038,85.025-0.038,170.073,0,255.099c-13.161,0.201-26.334,0.065-39.491,0.065C582.275,720.468,582.308,635.404,582.172,550.362
		L582.172,550.362z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M891.568,642.452c16.347-9.988,36.42-12.229,55.144-9.657
		c16.466,1.932,31.96,8.859,45.551,18.159c-6.76,8.691-13.156,17.681-19.487,26.697c-6.646-3.152-12.771-6.607-19.771-8.436
		c-13.146-4.797-35.71-6.094-45.486,5.696c-4.73,5.881-2.039,15.283,4.649,18.44c13.342,7.611,29.309,7.91,43.615,12.907
		c15.001,4.606,30.121,12.728,38.243,26.703c7.042,11.578,7.524,26.297,3.189,38.922c-5.496,15.766-18.907,27.614-34.456,33.078
		c-35.553,12.147-76.356,2.425-104.932-21.3c6.863-9.37,13.672-18.805,21.051-27.794c15.017,10.286,32.231,18.874,50.867,18.776
		c10.585,0.532,22.732-1.063,30.024-9.586c5.919-7.053,3.027-16.357-4.074-21.338c-6.18-4.335-13.559-6.83-20.964-8.188
		c-16.162-3.803-33.159-6.429-47.434-15.467c-12.109-7.623-20.665-21.496-19.949-36.052
		C866.883,667.04,877.349,651.105,891.568,642.452L891.568,642.452z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M229.864,655.223c31.733-26.66,81.838-26.248,114.171-0.76
		c-7.726,10.297-15.951,20.215-23.891,30.35c-12.41-7.775-27.346-11.746-41.951-9.305c-14.188,1.932-27.827,10.802-33.523,24.225
		c-9.67,20.188-4.937,47.4,13.222,61.257c19.29,14.366,48.747,11.659,65.923-4.855c9.454,8.957,18.177,18.657,27.479,27.762
		c-31.035,32.763-87.885,35.074-121.926,5.734c-19.238-16.531-29.207-42.302-28.51-67.404
		C200.41,697.177,210.626,671.591,229.864,655.223L229.864,655.223z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M70.839,637.352c19.854-3.076,40.986-0.233,58.447,10.031
		c19.141,10.416,33.01,29.227,38.892,50.06c6.315,23.372,3.822,49.408-8.42,70.47c-11.781,20.318-32.582,35.004-55.574,39.594
		c-20.7,3.804-42.979,1.379-61.336-9.304c-18.408-10.037-32.265-27.664-38.412-47.635c-7.625-22.89-5.284-48.807,5.981-70.106
		C22.196,657.518,45.454,641.252,70.839,637.352L70.839,637.352z M72.47,676.642c-13.126,3.435-24.59,13.173-29.489,25.917
		c-5.501,12.489-5.583,27.11-0.301,39.703c5.599,15.104,20.302,26.068,36.252,27.663c8.838,0.765,18.091,0.396,26.316-3.223
		c14.021-5.783,24.438-19.341,26.532-34.343c2.111-12.842,0.299-26.779-7.109-37.728c-6.779-10.498-18.46-16.997-30.537-19.304
		C86.89,674.782,79.528,674.797,72.47,676.642L72.47,676.642z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M665.093,640.091c13.157-0.151,26.318-0.119,39.459-0.032
		c-0.087,26.346-0.114,52.696,0.136,79.047c0.244,13.856,1.476,28.939,10.08,40.387c15.283,19.688,49.642,17.691,63.248-2.925
		c5.882-9.174,7.824-20.334,7.775-31.065c0.064-28.494,0.119-57.004,0.032-85.492c13.156-0.021,26.335-0.021,39.491,0.011
		c0.049,55.127,0.081,110.265-0.017,165.392c-11.464,0.299-22.943-0.017-34.407,0.185c-0.266-5.22-0.694-10.422-1.242-15.604
		c-27.035,23.958-71.859,25.981-99.864,2.643c-14.389-11.914-21.647-30.371-23.329-48.611
		C663.916,709.45,665.608,674.727,665.093,640.091L665.093,640.091z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M376.215,739.24c-1.131-33.062-0.583-66.141-0.684-99.219
		c13.192,0,26.383-0.13,39.575,0.104c-0.049,27.945-0.133,55.902,0.198,83.864c0.333,13.141,2.393,27.626,11.646,37.695
		c16.119,17.177,47.45,14.654,60.992-4.486c6.624-9.636,8.767-21.484,8.767-33.518l-0.331-83.572
		c12.996-0.282,25.99,0.049,38.994-0.185c0.353,55.176,0,110.346,0.168,165.521c-11.328-0.032-22.71,0.532-34.006-0.217
		c-0.917-5.034-1.047-10.167-1.514-15.234c-28.626,25.32-77.788,26.286-104.72-2.045
		C382.628,775.074,377.643,756.786,376.215,739.24L376.215,739.24z"
                        />
                      </g>
                    </svg>
                    <h4>Meta Quest 2</h4>
                    <h6 style={{paddingLeft: '40px', paddingRight: '40px'}}> [Available March 1st on AppLab] Immerse yourself through VR, be a part of it all like you're there!</h6>
                  </Col>
                  <Col className={'--extLink --brand'} sm={4} xs={12}>
                    <svg width="842" height="1000" viewBox="0 0 842 1000">
                      <path d="M702 960c-54.2 52.6-114 44.4-171 19.6-60.6-25.3-116-26.9-180 0-79.7 34.4-122 24.4-170-19.6-271-279-231-704 77-720 74.7 4 127 41.3 171 44.4 65.4-13.3 128-51.4 198-46.4 84.1 6.8 147 40 189 99.7-173 104-132 332 26.9 396-31.8 83.5-72.6 166-141 227zM423 237C414.9 113 515.4 11 631 1c15.9 143-130 250-208 236z" />
                    </svg>
                    <h4>Mobile Devices</h4>
                    <h6 style={{paddingLeft: '40px', paddingRight: '40px'}}> [To be announced] Use your phone to join on the go!</h6>
                  </Col>
                  <Col className={'--extLink --brand'} sm={4} xs={12}>
                    <svg width="58" height="58" version="1.1" viewBox="0 0 48.745 48.747">
                      <g>
                        <rect x="2.2848e-15" y="-.00011033" width="23.105" height="23.105" />
                        <rect x="25.64" y="-.00011033" width="23.105" height="23.105" />
                        <rect x="2.2848e-15" y="25.642" width="23.105" height="23.105" />
                        <rect x="25.64" y="25.642" width="23.105" height="23.105" />
                      </g>
                    </svg>
                    <h4>PC</h4>
                    <h6 style={{paddingLeft: '40px', paddingRight: '40px'}}> [To be announced] Jump in the Asimula with a mouse and keyboard!</h6>
                  </Col>
                </Row>
              </Container>
            </Container>
            {/* <Container className="--USP --snap">
              <Row>
                <Col sm={12} md={{ span: 6, offset: 6 }}>
                  <div className="USP">
                    <h1>Live streaming</h1>
                    <h4>Interact with your bias in real time!</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={{ span: 6, offset: 0 }}>
                  <div className="USP">
                    <h1>2-way interaction</h1>
                    <h4>Your bias really sees you!</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={{ span: 6, offset: 6 }}>
                  <div className="USP">
                    <h1>Telepresence</h1>
                    <h4>Join the concerts with your friends!</h4>
                  </div>
                </Col>
              </Row>
            </Container> */}
            {/* <Container fluid className="EmailFormContainer --snap">
              <div className="StarBackground">
                <Canvas>
                  <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade />
                </Canvas>
              </div>
              <Container>
                <Row>
                  <Col xs={12} md={{ span: 6, offset: 3 }}>
                    <h1>Large Text</h1>
                    <h5>Small text</h5>
                    <Form className="EmailForm">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Enter email" />
                      </Form.Group>

                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Container> */}
            <div className="SignUpPage --snap">
              <YoutubeBackground
                videoId={'AQZ3-ZTjxyk'} /* default -> null */
                aspectRatio={'9:16'} /* default -> "16:9" */
                overlay={'rgba(0,0,0,0.3)'} /* default -> null | e.g. "rgba(0,0,0,.4)" */
                className={'BGvideo'} /* default -> null */
                // nocookie={bool} /* default -> false | sets host to https:/*www.youtube-nocookie.com to avoid loading Google's cookies */
                // playerOptions={object} /* default -> {}  | https://developers.google.com/youtube/player_parameters*/
                // onReady={func} /* default -> null | returns event with player object */
                // onEnd={func} /* default -> null | returns event with player object */
                // onPlay={func} /* default -> null | returns event with player object */
                // onPause={func} /* default -> null | returns event with player object */
                // onError={func} /* default -> null | returns event with player object */
                // onStateChange={func} /* default -> null | returns event with player object */
                // onPlaybackRateChange={func} /* default -> null | returns event with player object */
                // onPlaybackQualityChange={func} /* default -> null | returns event with player object */
              >
                {/* YOUR CONTENT */}
                <div className="RegisteredUsers">
                  <h3>
                    {/* signupNumber */}
                    Registered Users - <b>{6204}</b> 
                  </h3>
                </div>
              </YoutubeBackground>
              <div className="SignUpPanel">
                <h1>Sign Up</h1>
                <Form className="SignUpForm">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      <h5>Email address</h5>
                    </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      <h5>Password</h5>
                    </Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Sign Up
                  </Button>
                </Form>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      );
    }
  }

  return <div>{getContent()}</div>;
}