import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Navigation from './Navigation';
import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import YoutubeBackground from 'react-youtube-background';

export default function Events() {
  const [signupNumber, setSignupNumber] = useState(6204); //this user count should be updated automatically (manual input for now)
  return (
    <React.Fragment>
      <div className={'Events'}>
        <Navigation />
        <div className="ContentWrapper">
        <div className="SignUpPage --snap">
              <YoutubeBackground
                videoId={'AQZ3-ZTjxyk'} /* default -> null */
                aspectRatio={'9:16'} /* default -> "16:9" */
                overlay={'rgba(0,0,0,0.3)'} /* default -> null | e.g. "rgba(0,0,0,.4)" */
                className={'BGvideo'} /* default -> null */
                // nocookie={bool} /* default -> false | sets host to https:/*www.youtube-nocookie.com to avoid loading Google's cookies */
                // playerOptions={object} /* default -> {}  | https://developers.google.com/youtube/player_parameters*/
                // onReady={func} /* default -> null | returns event with player object */
                // onEnd={func} /* default -> null | returns event with player object */
                // onPlay={func} /* default -> null | returns event with player object */
                // onPause={func} /* default -> null | returns event with player object */
                // onError={func} /* default -> null | returns event with player object */
                // onStateChange={func} /* default -> null | returns event with player object */
                // onPlaybackRateChange={func} /* default -> null | returns event with player object */
                // onPlaybackQualityChange={func} /* default -> null | returns event with player object */
              >
                {/* YOUR CONTENT */}
                <div className="RegisteredUsers">
                  <h3>
                    Registered Users - <b>{signupNumber}</b>
                  </h3>
                </div>
              </YoutubeBackground>
              <div className="SignUpPanel">
                <h1>Sign Up</h1>
                <Form className="SignUpForm">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      <h5>Email address</h5>
                    </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      <h5>Password</h5>
                    </Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                  </Form.Group>
                  <Button href="/" variant="primary" type="submit">
                    Sign Up
                  </Button>
                </Form>
              </div>
            </div>
        </div>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}