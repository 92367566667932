import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Navigation from './Navigation';

export default function Events() {
  return (
    <React.Fragment>
      <div className={'Events'}>
        <Navigation />
        <div className="ContentWrapper">
          {/* <h1>[Events] Currently Under Construction...</h1> */}
          <h1>The community is not yet able to view our upcoming lineup nor vote for artists and events.</h1>
        </div>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}