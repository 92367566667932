import {Stars, Box, Environment, useFBX, useTexture, SpotLight, PerspectiveCamera, Plane, Image} from '@react-three/drei';
import React, { forwardRef, useRef, Suspense, useFrame } from 'react';
import { AlphaFormat, DoubleSide } from 'three';
import stage_fbx from './asset/stage.fbx';
import drone_fbx from './asset/drone.fbx';

import { PlaneGeometry } from 'three';
import { BoxGeometry, LinearFilter, TextureLoader } from 'three';


const Scene = (props, ref) => {
  const stage = useFBX(stage_fbx); // This was peana : Daehyeon
  const drone = useFBX(drone_fbx);

  var mamamooText = new TextureLoader().load('mamamoo2.jpg');
  mamamooText.generateMipmaps = false;
  mamamooText.minFilter = LinearFilter;
  mamamooText.needsUpdate = true;

  var mamamooAlphaText = new TextureLoader().load('mamamoo_alpha.png');


  const beamPosition = [-0.5, 3.7, 1.5];

  return (
    <React.Fragment>
      <PerspectiveCamera makeDefault position={[0, 2.5, 9]} />
      <mesh>

        <mesh>
          <planeGeometry args={[4.0,4.0]}/>
          <meshStandardMaterial map={mamamooText} alphaMap={mamamooAlphaText} transparent={true}/>
        </mesh> 
        
        
        <mesh>
          <primitive object={stage} position={[0, -2.5, -0.7]} scale={0.12} // This was 0.005 : Daehyeon
          /> 
        </mesh>
      </mesh>

      <mesh ref={ref} rotation={[0, 0.1, 0.5]}>

        <mesh>

          <primitive object={drone} position={beamPosition} scale={0.0065} rotation={[0.6,4.0,1.4]} // This was 0.005 : Daehyeon
          /> 
          </mesh>


        <SpotLight // Motion capture beam
          color={'#0099ff'}
          castShadow={true}
          distance={10}
          angle={1.1}
          decay={3}
          power={6}
          attenuation={5}
          anglePower={5} // Diffuse-cone anglePower (default: 5)
          position={beamPosition}
          scale={[0.1, 1, 1]}
          rotation={[0, 0, 0]}
        />
      </mesh>
      
      <SpotLight // Ambient light from the top
        distance={9}
        angle={5}
        attenuation={0}
        anglePower={10} // Diffuse-cone anglePower (default: 5)
        position={[0, 5, 4]}
        scale={[1, 1, 1]}
        power={9}
        decay={0.7}
      />
      
    </React.Fragment>
  );
};

export default forwardRef(Scene);
